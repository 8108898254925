// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/:organizationId`
  | `/:organizationId/adoption`
  | `/:organizationId/chats/:chatId`
  | `/:organizationId/learn/:workshopId`
  | `/:organizationId/learn/:workshopId/course/:courseId`
  | `/:organizationId/learn/:workshopId/course/:courseId/done`
  | `/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId`
  | `/:organizationId/onboarding`
  | `/:organizationId/settings`
  | `/:organizationId/settings/dataPool/:id`
  | `/:organizationId/teach`
  | `/:organizationId/teach/*`
  | `/:organizationId/test`
  | `/:organizationId/tools/imageFactory`
  | `/:organizationId/tools/meetingRecorder`
  | `/:organizationId/tools/meetingRecorder/:meetingId`
  | `/:organizationId/tools/researchAssistant`
  | `/:organizationId/tools/supportAssistant`
  | `/:organizationId/tools/transcriptSummarizer`
  | `/:organizationId/tools/translateContent`
  | `/:organizationId/workflows/:workflowId`
  | `/auth`
  | `/invites`
  | `/magic-link/:token`
  | `/teach`
  | `/teach/:workshopId`
  | `/teach/course/:courseId`
  | `/workshops/join`

export type Params = {
  '/:organizationId': { organizationId: string }
  '/:organizationId/adoption': { organizationId: string }
  '/:organizationId/chats/:chatId': { organizationId: string; chatId: string }
  '/:organizationId/learn/:workshopId': { organizationId: string; workshopId: string }
  '/:organizationId/learn/:workshopId/course/:courseId': { organizationId: string; workshopId: string; courseId: string }
  '/:organizationId/learn/:workshopId/course/:courseId/done': { organizationId: string; workshopId: string; courseId: string }
  '/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId': { organizationId: string; workshopId: string; courseId: string; exerciseId: string }
  '/:organizationId/onboarding': { organizationId: string }
  '/:organizationId/settings': { organizationId: string }
  '/:organizationId/settings/dataPool/:id': { organizationId: string; id: string }
  '/:organizationId/teach': { organizationId: string }
  '/:organizationId/teach/*': { organizationId: string; '*': string }
  '/:organizationId/test': { organizationId: string }
  '/:organizationId/tools/imageFactory': { organizationId: string }
  '/:organizationId/tools/meetingRecorder': { organizationId: string }
  '/:organizationId/tools/meetingRecorder/:meetingId': { organizationId: string; meetingId: string }
  '/:organizationId/tools/researchAssistant': { organizationId: string }
  '/:organizationId/tools/supportAssistant': { organizationId: string }
  '/:organizationId/tools/transcriptSummarizer': { organizationId: string }
  '/:organizationId/tools/translateContent': { organizationId: string }
  '/:organizationId/workflows/:workflowId': { organizationId: string; workflowId: string }
  '/magic-link/:token': { token: string }
  '/teach/:workshopId': { workshopId: string }
  '/teach/course/:courseId': { courseId: string }
}

export type ModalPath = `/[organizationId]/tools/profileSettings` | `/[organizationId]/tools/supportAssistantAnalytics`

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
